<template>
  <div
    v-if="richText"
    :style="bg_color ? `background-color: ${bg_color}` : ''"
    :class="{ 'sb-rich-text-card': card, 'u-p-y-xl': padding_large }"
  >
    <div :class="{ 'inner-container fad': !no_padding }">
      <div
        class="sb-rich-text"
        :class="{ 'text-center': text_center }"
        :style="` ${text_color ? `color: ${text_color} !important` : ''};--card-bg-color: ${
          card_color || 'transparent'
        }`"
      >
        <div v-html="richText" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbRichText',
  props: {
    text: { type: Object, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    text_center: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: null },
    // eslint-disable-next-line vue/prop-name-casing
    text_color: { type: String, default: null },
    card: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    card_color: { type: String, default: null },
    // eslint-disable-next-line vue/prop-name-casing
    padding_large: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    no_padding: { type: Boolean, default: false },
  },
  computed: {
    richText() {
      if (this.text) {
        return renderRichText(this.text)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.sb-rich-text-card {
  .sb-rich-text {
    padding: 0 $spacing-md;
    background-color: var(--card-bg-color);
    border-radius: $spacing-xs;
    @include mq($mq-md) {
      padding: $spacing-md;
    }
  }
}
.sb-rich-text {
  padding-bottom: $spacing-sm;
  padding-top: $spacing-md;

  @include mq($mq-xs) {
    padding-top: $spacing-md;
  }

  &.text-center * {
    text-align: center;
  }
  h3 {
    margin-bottom: $spacing-md;
    @include mq($mq-sm) {
      font-size: pxToRem(20px);
    }
  }
  p {
    min-height: $spacing-sm;
  }
  b {
    font-weight: 800;
  }
  i {
    font-style: italic;
  }
  ul {
    margin-left: $spacing-md;
    li {
      list-style-type: disc;
    }
  }
  ol {
    margin-left: $spacing-md;
    li {
      list-style-type: decimal;
    }
  }
  .u-text-xxxl {
    @include mq($mq-sm) {
      font-size: pxToRem(30px);
    }
  }
}
</style>
